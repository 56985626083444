.container {
    border: 1px solid black;
    width: 800px;
    height: 100px;
    margin-top: 50px;
    margin-left: 50px;
}

.bar {
}

.d3-tip {
    line-height: 1;
    padding: 8px;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 4px;
    font-size: 16px;
    pointer-events: none;
}

/* Style northward tooltips specifically */
.d3-tip.n:after {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    content: "\25BC";
    position: absolute;
    text-align: center;

    margin: -2px 0 0 0;
    top: 100%;
    left: 0;
}

/* Style southward tooltips specifically */
.d3-tip.s:before {
    box-sizing: border-box;
    display: inline;
    font-size: 10px;
    width: 100%;
    line-height: 1;
    color: rgba(0, 0, 0, 0.8);
    content: "\25B2";
    position: absolute;
    text-align: center;

    margin: 0 0 -2px 0;
    bottom: 100%;
    left: 0;
}
